/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
import $ from 'jquery'
import 'jquery-ui'
import RailsUjs from '@rails/ujs'
import * as ActiveStorage from '@rails/activestorage'

import 'autosize'

import 'jquery-mask-plugin'
import flatpickr from 'flatpickr'
// import 'foundation-sites'
import Cookies from 'js-cookie'

import toastr from '@legacy/toastr.min'

import 'styles/application'

// // Legacy Javascript
import '@legacy/customers'
import '@legacy/products'
import '@legacy/registration'

const images = require.context('../images', true)
const imagePath = (name) => images(name, true) // eslint-disable-line no-unused-vars

RailsUjs.start()
ActiveStorage.start()

// THIS IS MAKING jQuery AVAILABLE EVEN INSIDE Views FOLDER
global.$ = $

window.Cookies = Cookies

global.toastr = toastr

$.jMaskGlobals.watchDataMask = true

// /*global toastr*/
$(document).ready(function () {
  toastr.options = {
    closeButton: true,
    debug: false,
    newestOnTop: false,
    progressBar: false,
    positionClass: 'toast-top-right',
    preventDuplicates: false,
    onclick: null,
    showDuration: '300',
    hideDuration: '1000',
    timeOut: '5000',
    extendedTimeOut: '1000',
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut'
  }

  // $(function () { $(document).foundation() })

  flatpickr('.date-picker')
})

// document.addEventListener('turbolinks:load', () => $(document).foundation())
