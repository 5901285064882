
getUrlParameter = (sParam) ->
  sPageURL = window.location.search.substring(1)
  sURLVariables = sPageURL.split('&')
  sParameterName = undefined
  i = undefined
  i = 0

  while i < sURLVariables.length
    sParameterName = sURLVariables[i].split('=')
    if sParameterName[0] == sParam
      return if sParameterName[1] == undefined then true else decodeURIComponent(sParameterName[1])
    i++
  return

setFormFromUrl = ->
  invoice_number = getUrlParameter("invoice_number")
  email = getUrlParameter("email")

  if !!invoice_number
    $("#customer_invoice_number").val(invoice_number)

  if !!email
    $("#customer_email").val(email)

# registerRegistrationEvents = ->

prepareRegistrationScript = ->
  setFormFromUrl()


$(document).ready(prepareRegistrationScript)
$(document).on("turbolink:load", prepareRegistrationScript)
